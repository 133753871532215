import React, { useState, useRef, useEffect } from 'react'
import Logo from './Logo'
import Button from '../components/Button'
import useStore from '../store'
import { cn } from '../lib/utils'
import contentfulClient from '../lib/contentful'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import circleLogo from '../images/NAT-HAB-2ColorCompass_White.png'
import { Category } from '../GuidePortal/Article'
import client from '../lib/contentful'
import { v4 as uuidv4 } from 'uuid';
import tripStore from '../TripPage/trip_store'
import { useForm } from 'react-hook-form'

function DropDownLink({url, text, children, to, disabled}) {
  const classList = "text-xs flex justify-start cursor-pointer items-center text-link gap-2"
  const disabledClasses = "text-gray-400 cursor-not-allowed"
  return (
    <li className="dropdown-item mb-2">
      {disabled
        ? <span className={cn(classList, disabledClasses)}>{children ? children : text}</span>
        : to
          ? <Link to={to} className={classList}>{children ? children : text}</Link>
          : <a href={url} className={classList}>{children ? children : text}</a>
      }
    </li>
  )
}

function Dropdown({title, children, linkClasses, position, className}) {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  function toggleDropdown(e) {
    e.preventDefault()
    setIsOpen(!isOpen)
  }

  function handleClickOutside(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <li className={cn("md:relative h-full", className)} ref={dropdownRef}>
      <a href="#" onClick={toggleDropdown} className={linkClasses}>
        {title}
        <i className={`hidden md:inline fa-regular fa-chevron-up relative transition-all duration-250 ${isOpen ? "" : "-scale-100 -top-[2px]"}`}></i>
      </a>
      <div className={`absolute z-50 left-0 right-0 top-full ${position == 'right' ? 'md:left-auto' : 'md:right-auto'} pt-8 pb-2 md:py-2 bg-white border-b md:border-x border-gray-300 md:rounded-b shadow md:w-64 text-black ${isOpen ? "block" : "hidden"}`}>
        <div className="-mb-2">
          {children}
        </div>
      </div>
    </li>
  );
};

export default function (props) {
  const linkClasses = "text-white text-sm border-b-0 hover:border-b-0 hover:text-white active:text-white focus:text-white flex gap-2 items-center h-full"
  const [photoShareTravelers, setPhotoShareTravelers] = useState([])
  const [tid, setTid] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [searching, setSearching] = useState(false)
  const [navOpen, setNavOpen] = useState(false)
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  // make sure these work outside of react as we still have some non-react pages
  const searchRef = useRef(null)

  let navigate
  let location

  try {
    navigate = useNavigate()
    location = useLocation()
  } catch (e) {
    location = {pathname: '/'}
    navigate = (url) => window.location.href = url
  }

  const [
    user,
    fetchUser,
    pastTravelers,
    upcomingTravelers,
    dismissAccountSwitcher,
  ] = useStore((state) => [
    state.user,
    state.fetchUser,
    state.pastTravelers,
    state.upcomingTravelers,
    state.dismissAccountSwitcher,
  ])

  const [
    tripTraveler,
  ] = tripStore((state) => [
    state.tripTraveler,
  ])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  useEffect(() => {
    if (typeof pastTravelers !== 'Array') return
    setPhotoShareTravelers(pastTravelers.filter((trip) => trip.photo_sharing))
  }, [pastTravelers])

  const printStyles = {
    WebkitPrintColorAdjust: 'exact',
    MozPrintColorAdjust: 'exact',
    msPrintColorAdjust: 'exact',
  }

  const { register, handleSubmit } = useForm()

  useEffect(() => {
    setNavOpen(false)
    setMobileSearchOpen(false)
  }, [location.pathname])

  function search(e) {
    if (e.target.value.length < 2) return
    if (tid) clearTimeout(tid)
    setTid(setTimeout(() => {
      setSearching(true)
      contentfulClient.getEntries({
        content_type: 'fieldTeamPortalCategory',
        query: e.target.value,
        'sys.id[nin]': '5xL5TY8oZJiVrzG39n1TiR,wPBMtgajIiSGP3Ad9ZFNF,azIRvMX0Iy3I9JmyJvNEn',
        limit: 10,
      }).then((response) => {
        setSearching(false)
        setSearchResults(response.items)
        setSearchOpen(true)
      }).catch(console.error)
    }, 500))
  }

  async function getTopLevelCategory(id, parents = []) {
    const category = await contentfulClient.getEntries({
      content_type: 'fieldTeamPortalCategory',
      'fields.children.sys.id': id,
      limit: 1,
    })
    parents.push(category.items[0])
    if (parents.length > 5) return console.error('Too many tries')
    if (['wPBMtgajIiSGP3Ad9ZFNF' ,'azIRvMX0Iy3I9JmyJvNEn'].includes(category.items[0].sys.id)) return parents
    return await getTopLevelCategory(category.items[0].sys.id, parents)
  }

  async function goToResult(result) {
    const parents = await getTopLevelCategory(result.sys.id)
    setSearchResults([])
    if (parents.length > 2) {
      navigate(`/field-team/category/${parents[parents.length - 1].fields.slug}/article/${parents[0].fields.slug}#${result.fields.slug}`)
    } else {
      navigate(`/field-team/category/${parents[parents.length - 1].fields.slug}/article/${result.fields.slug}`)
    }
  }

  const toggleBodyScroll = (disable) => {
    const body = document.body;
    if (disable) {
      body.classList.add('overflow-hidden');
      // body.classList.add('pr-4');
    } else {
      body.classList.remove('overflow-hidden');
      // body.classList.remove('pr-4');
    }
  };
  useEffect(() => {
    toggleBodyScroll(navOpen || mobileSearchOpen);
    return () => {
      toggleBodyScroll(false);
    };
  }, [navOpen, mobileSearchOpen])

  function handleClickOutsideSearch(event) {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideSearch);
    return () => {
      document.removeEventListener("click", handleClickOutsideSearch);
    };
  }, []);

  function navigateToReservation(data) {
    window.location = `/admin/users/${data.res_id}/reservation`
  }

  useEffect(() => {
    if (localStorage.getItem('uniqueVisitorID')) return
    localStorage.setItem('uniqueVisitorID', uuidv4())  
  }, [])

  return <>
    <div
      className="bg-sky-800 bg-gradient-to-r from-sky-800 to-sky-600 text-white px-2 md:px-4 relative print:hidden"
      style={{backgroundImage: "linear-gradient(to right, #075985, #0284c7"}}
    >
      <div className="flex gap-4 items-stretch justify-start min-h-[2.75rem]">
        {user && user.id && user.isGuide &&
          <button className="md:hidden flex flex-col justify-center" onClick={() => setNavOpen(!navOpen)}>
            <div className="relative w-10 h-8">
              <div className={cn("absolute opacity-0 top-0 left-0 right-0 bottom-0 transition-opacity flex justify-center items-center", navOpen && 'opacity-100')}>
                <i className={cn("fa-solid fa-xmark text-2xl ")}></i>
              </div>
              <div className={cn("absolute opacity-0 top-0 left-0 right-0 bottom-0 transition-opacity flex justify-center items-center", !navOpen && 'opacity-100')}>
                <i className={cn("fa-solid fa-bars text-2xl")}></i>
              </div>
            </div>
          </button>
        }
        <div className="flex flex-col justify-center md:hidden">
          <img src={circleLogo} alt="Nat Hab Compass" className="h-8 w-auto" />
        </div>
        <Logo />
        <div className="hidden md:block font-condensed text-xl font-bold self-center">
          Adventure Portal
        </div>
        {user && user.id &&
          <>
            <div className="grow">
              <ul className="flex gap-3 xl:gap-4 items-stretch h-full">
                {(user.isGuide || user.is_admin) && <>
                  <li className="h-full block">
                    <a href="/field-team" className={linkClasses}>Field Team</a>
                  </li>
                </>}
                {user.isGuide
                  ? <li className="h-full">
                      <a href="/" className={linkClasses}>Guest Portal</a>
                    </li>
                  : <li className="h-full">
                      <a href="/" className={linkClasses}>Home</a>
                    </li>
                }
                {user.is_admin && <>
                  <Dropdown title="Admin" linkClasses={linkClasses}>
                    <ul className="px-2">
                      <DropDownLink url="/admin" text="Summary" />
                      <DropDownLink url="/admin/users" text="Users" />
                      <DropDownLink url="/admin/pages" text="Pages" />
                      <DropDownLink url="/admin/albums" text="Albums" />
                      <DropDownLink url="/admin/photos" text="Photos" />
                      <DropDownLink url="/admin/trips" text="Trips" />
                      <DropDownLink url="/admin/guides" text="Guides" />
                      <DropDownLink url="/admin/site-inspection" text="Site Inspection" />
                      <DropDownLink url="/admin/assign-photos" text="Assign Photos" />
                      <DropDownLink url="/admin/my-assignments" text="Judge Photos" />
                      <DropDownLink url="/admin/completed-assignments" text="Completed Assignments" />
                      <DropDownLink url="/admin/completed-video-assignments" text="Completed Video Assignments" />
                      <DropDownLink url="/admin/media-requests" text="Media Requests" />
                      {(user.user_id == 16234) &&
                        <DropDownLink url="/admin/delayed-job" text="Jobs" />
                      }
                      {user.recently_impersonated && user.recently_impersonated.length > 0 &&
                        <>
                          <li className="dropdown-item mb-2 border-b -mx-2"></li>
                          <li className="dropdown-item mb-2 font-bold">Recently Impersonated</li>
                          {user.recently_impersonated.map((u) =>
                            <DropDownLink key={u.url} url={u.url} text={u.full_name} />
                          )}
                        </>
                      }
                    </ul>
                  </Dropdown>
                </>}
                {!user.is_admin && user.is_marketing &&
                  <Dropdown title="Marketing" linkClasses={linkClasses}>
                    <ul className="px-2">
                      <DropDownLink url="/admin/albums" text="Albums" />
                      <DropDownLink url="/admin/photos" text="Photos" />
                    </ul>
                  </Dropdown>
                }
                {user.media_requests && user.media_requests.length > 0 &&
                  <Dropdown title={ <>Media Requests<span className="rounded-full bg-red-500 text-white w-4 h-4 inline-block text-xs flex justify-center items-center">{user.media_requests.length}</span></> } linkClasses={linkClasses}>
                    <ul className="px-2">
                      {user.media_requests.map((request) =>
                        <DropDownLink key={request.id} url={`/media-requests/${request.id}`} text={request.full_title} />
                      )}
                    </ul>
                  </Dropdown>
                }
                <div className="grow"></div>
                {(user.is_admin) && <>
                  <div className="p-1 relative hidden md:flex flex-col justify-center">
                    <form className="rounded bg-sky-800 outline-none shadow-inset text-white px-2 py-1 leading-0 flex gap-2 items-center relative" onSubmit={handleSubmit((data) => navigateToReservation(data))}>
                      <i className="fa-duotone fa-solid fa-earth-americas"></i>
                      <input type="text" {...register('res_id')} className="bg-transparent placeholder:text-gray-50 outline-none w-12 lg:w-16 xl:w-24" placeholder="Res #" />
                    </form>
                  </div>
                </>}
                {(user.isGuide || user.is_admin) && <>
                  <div className="md:hidden flex items-center" onClick={() => setMobileSearchOpen(!mobileSearchOpen)}>
                    <i className="fa-solid fa-magnifying-glass text-xl md:hidden"></i>
                  </div>
                  <div className="p-1 relative hidden md:flex flex-col justify-center" ref={searchRef}>
                    <div className="rounded bg-sky-800 outline-none shadow-inset text-white px-2 py-1 leading-0 flex gap-2 items-center relative">
                      {searching
                        ? <i className="fa-solid fa-spinner-third animate-spin"></i>
                        : <i className="fa-solid fa-magnifying-glass"></i>
                      }
                      <input type="text" className="bg-transparent placeholder:text-gray-50 outline-none w-12 lg:w-16 xl:w-24" placeholder="Search" onFocus={e => setSearchOpen(true)} onChange={search} />
                    </div>
                    {searchResults.length > 0 && searchOpen &&
                      <div className="absolute top-full right-0 bg-white border border-gray-300 w-64 rounded-b shadow z-50">
                        {searchResults.map((result) =>
                          <button key={result.sys.id} className="text-link cursor-pointer block p-2 border-t first:border-t-0 text-left w-full" onClick={() => goToResult(result)}>{result.fields.title}</button>
                        )}
                      </div>
                    }
                  </div>
                </>}
                <Dropdown title={<><i className="fa-regular fa-circle-user text-xl md:text-base"></i><span className="hidden lg:inline">{tripTraveler.id ? tripTraveler.nameDisplay_c : user.nameDisplay_c}</span></>} linkClasses={linkClasses} position="right">
                  <ul className="px-2">
                    {tripTraveler.people && tripTraveler.people.length > 1 && <>
                      <li className="text-xs mb-2">Switch Account</li>
                      {tripTraveler.people.map((person) =>
                        <DropDownLink key={person.id} url={`/trip/${person.traveler_record_id}/your-trip`} className="pl-2" disabled={person.traveler_id == null}>
                          {person.traveler_id == tripTraveler.id ?
                            <div className="text-white bg-green-600 rounded-full w-4 h-4 flex justify-center items-center relative left-1">
                              <i className="fa-solid fa-check relative top-px"></i>
                            </div>
                            :
                            <div className="w-4 h-4"></div>
                          }
                          {person.nameDisplay_c}
                        </DropDownLink>
                      )}
                      <li className="dropdown-item mb-2 border-b -mx-2"></li>
                    </>
                    }
                    {!(tripTraveler.people && tripTraveler.people.length > 1) && user.people && user.people.length > 0 && <>
                      <li className="text-xs mb-2">Switch Account</li>
                      {user.people.map((person) =>
                        <DropDownLink key={person.id} url={`/switch-user/${person.id}`} className="pl-2">
                          {person.id == user.id ?
                            <div className="text-white bg-green-600 rounded-full w-4 h-4 flex justify-center items-center relative left-1">
                              <i className="fa-solid fa-check relative top-px"></i>
                            </div>
                            :
                            <div className="w-4 h-4"></div>
                          }
                          {person.nameFirst} {person.nameLast}
                        </DropDownLink>
                      )}
                      <li className="dropdown-item mb-2 border-b -mx-2"></li>
                    </>}
                    {!user.is_sso &&
                      <DropDownLink url="/profile/edit" text="Edit Profile" />
                    }
                    <DropDownLink url="/session/sign-out" text="Sign Out" />
                  </ul>
                </Dropdown>
              </ul>
            </div>
          </>
        }
      </div>
    </div>
    <div className={cn("md:hidden fixed top-[2.75rem] left-0 bottom-0 right-0 bg-black/40 z-50 hidden", navOpen && "block")} onClick={() => setNavOpen(false)}>
      <div className="w-[calc(100vw-4rem)] sm:w-[calc(100vw-8rem)] bg-white h-full shadow-md overflow-auto" onClick={(e) => e.stopPropagation()}>
        <MobileNav />
      </div>
    </div>
    <div className={cn("md:hidden fixed top-[2.75rem] left-0 bottom-0 right-0 bg-black/40 z-50 hidden", mobileSearchOpen && "block")} onClick={() => setMobileSearchOpen(false)}>
      <div className="max-h-[calc(100vh-8rem)] bg-white w-full shadow-md overflow-auto" onClick={(e) => e.stopPropagation()}>
        <div className="p-2 grid grid-col gap-2">
          <div className="rounded bg-white outline-none shadow-inset border px-2 py-1 leading-0 flex gap-2 items-center">
            {searching
              ? <i className="fa-solid fa-spinner-third animate-spin"></i>
              : <i className="fa-solid fa-magnifying-glass"></i>
            }
            <input type="text" className="bg-transparent placeholder:text-gray-50 outline-none grow" placeholder="Search" onChange={search} />
          </div>
          {searchResults.length > 0 &&
            <div className="">
              {searchResults.map((result) =>
                <button key={result.sys.id} className="text-link text-xl font-bold cursor-pointer block p-2 border-t first:border-t-0 text-left w-full" onClick={() => goToResult(result)}>{result.fields.title}</button>
              )}
            </div>
          }
        </div>
      </div>
    </div>
  </>
}

function MobileNav() {
  const linkClasses = "text-2xl sm:text-4xl font-bold text-link flex gap-4 sm:gap-6 items-center px-4 sm:px-6 py-4"
  const [category, setCategory] = useState(false)
  const [topLevelCategories, setTopLevelCategories] = useState([])
  const { categoryId } = useParams()

  function loadCategory(categoryId) {
    client.getEntries({content_type: 'fieldTeamPortalCategory', 'fields.slug': categoryId})
      .then((response) => {
        setCategory(response.items[0])
      })
      .catch(console.error)
  }

  useEffect(() => {
    if (!categoryId) return
    loadCategory(categoryId)
  }, [categoryId])

  useEffect(() => {
    client.getEntry('5xL5TY8oZJiVrzG39n1TiR')
      .then((item) => {
        setTopLevelCategories(item.fields.children)
      })
      .catch(console.error)
  }, [])

  return (
    <div className="px-4 sm:px-6 py-12">
      <div className="flex flex-col sm:gap-4">
        {category
          ? <>
            <button className={linkClasses} onClick={() => setCategory(false)}>
              <i className="fa-solid fa-arrow-left"></i>
              Main Menu
            </button>
            <hr />
            <div className={cn(linkClasses, 'text-black')}>
              <i className={category.fields?.icon}></i>
              {category.fields?.title}
            </div>
            {category.fields?.children?.map((c) =>
              <Category key={c.sys.id} topLevelCategory={category} category={c} />
            )}
          </>
          : <>
            <a href="/field-team" className={linkClasses}>
              <i className="fa-solid fa-house"></i>
              Home
            </a>
            {topLevelCategories.map((item) =>
              <button className={linkClasses} onClick={() => loadCategory(item.fields.slug)} key={item.sys.id}>
                <i className={item.fields.icon}></i>
                {item.fields.title}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            )}
            <a href="/field-team/trips" className={linkClasses}>
              <i className="fa-solid fa-globe"></i>
              My Trips
            </a>
            <a href="/field-team/category/how/article/emergencies-and-vhs" className={linkClasses}>
              <i className="fa-solid fa-triangle-exclamation"></i>
              Emergencies & VHS
            </a>
            <hr />
            <a href="/field-team/profile" className={linkClasses}>
              <i className="fa-solid fa-circle-user"></i>
              Profile
              <i className="fa-solid fa-arrow-right"></i>
            </a>
          </>
        }
      </div>
    </div>
  )
}
